<template>
  <div>
    <v-breadcrumbs :items="crumbs"></v-breadcrumbs>
    <base-loader :isLoading="isLoading"></base-loader>
    <v-row>
      <v-col cols="12" sm="12" md="12">
        <v-card>
          <v-card-text>
            <h4 class="text-md-h4 primary--text mb-5">
              {{ `${isEdit ? "Edit" : "New"} FicaProduct` }}
            </h4>
            <v-form ref="ficaProductForm" @submit.prevent="save()">
              <v-row>
                <v-col md="6">
                  <v-text-field
                    v-model="ficaProductModel.name"
                    label="Name"
                    required
                    outlined
                    :rules="[(v) => !!v || 'Fica Product Name is required']"
                  ></v-text-field>
                </v-col>
                <v-col md="6">
                  <v-text-field
                    v-model="ficaProductModel.description"
                    label="Description"
                    required
                    counter="50"
                    outlined
                    :rules="[
                      (v) => !!v || 'Fica Product Description is required',
                    ]"
                  ></v-text-field>
                </v-col>

                <v-col md="6">
                  <base-fica-product-category-picker
                    :chosen-fica-product-category.sync="
                      ficaProductModel.category
                    "
                    :initial="ficaProductModel.category"
                    label="Fica Category"
                    required
                    outlined
                    :rules="[(v) => !!v || 'Fica Product Category is required']"
                  />
                </v-col>
              </v-row>
              <v-btn type="submit" color="success">Save</v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { gql } from "apollo-boost";
export default {
  name: "EditFicaProducts",
  data() {
    return {
      isEdit: false,
      isLoading: false,

      installationImageAsDataURL: null,
      me: "",
      search: null,
    };
  },
  created() {
    this.isEdit = this.$route.params.id != 0;
    this.isLoading = this.isEdit ? true : false;
  },
  computed: {
    crumbs() {
      return [
        {
          text: "FicaProducts",
          disabled: false,
          href: "/admin/ficaproducts",
        },
        {
          text: "FicaProduct Detail",
          disabled: false,
          href: `/admin/ficaproduct/${this.$route.params.id}`,
        },
        {
          text: `${this.isEdit ? "Edit" : "New"} FicaProduct`,
          disabled: true,
        },
      ];
    },
  },
  watch: {},
  apollo: {
    me: gql`
      query {
        me {
          id
        }
      }
    `,
    ficaProduct: {
      query: gql`
        query FicaProduct($id: ID!) {
          ficaProduct(id: $id) {
            id
            name
            description
            category
            documents {
              id
            }
            createdBy
          }
        }
      `,
      fetchPolicy: "network-only",
      skip() {
        return !this.isEdit;
      },
      variables() {
        const self = this;
        return {
          id: self.$route.params.id,
        };
      },
      result(response, key) {
        this.isLoading = false;
        this.ficaProductModel.id = this.ficaProduct.id;
        this.ficaProductModel.name = this.ficaProduct.name;
        this.ficaProductModel.description = this.ficaProduct.description;
        this.ficaProductModel.category = this.ficaProduct.category;
        this.ficaProductModel.createdBy = this.ficaProduct.createdBy;
      },
    },
  },
  methods: {
    async save() {
      this.isLoading = true;
      const self = this;
      if (!self.$refs.ficaProductForm.validate()) {
        self.$swal("Error", "Validation failed", "error");
        self.isLoading = false;
        return;
      }
      if (self.isEdit) {
        await this.updateFicaProduct()
          .then(() => {
            this.$swal("Success!", "FicaProduct was updated", "success");
            window.location.href = `/admin/ficaproducts`;
          })
          .catch((e) => {
            this.$swal(
              "Error!",
              "something went wrong when trying to update the ficaProduct, check data and try again later!",
              "error"
            );
          });
      } else {
        await this.createFicaProduct()
          .then(() => {
            this.$swal("Success!", "FicaProduct was created", "success");
            window.location.href = `/admin/ficaproduct/${self.ficaProductModel.id}/edit`;
          })
          .catch((e) => {
            this.$swal(
              "Error!",
              `Something went wrong when creating the ficaProduct, check data and try again later!`,
              "error"
            );
          });
      }
      this.isLoading = false;
    },
    async updateFicaProduct() {
      const self = this;
      try {
        const response = await self.$apollo.mutate({
          mutation: gql`
            mutation update($ficaProduct: FicaProductUpdateInput!) {
              updateFicaProduct(ficaProduct: $ficaProduct) {
                id
                updated
              }
            }
          `,
          variables: {
            ficaProduct: {
              id: self.ficaProductModel.id,
              name: self.ficaProductModel.name,
              description: self.ficaProductModel.description,
              category: self.ficaProductModel.category,
            },
          },
        });
        if (!response) {
          throw "Something went wrong when creating the ficaProduct!";
        }
        if (response.errors && response.errors.length > 0) {
          throw response.errors[0];
        }
      } catch (e) {
        throw e.message;
      }
    },
    async createFicaProduct() {
      const self = this;
      try {
        const response = await self.$apollo.mutate({
          mutation: gql`
            mutation createFicaProduct($ficaProduct: FicaProductCreateInput!) {
              createFicaProduct(ficaProduct: $ficaProduct) {
                id
              }
            }
          `,
          variables: {
            ficaProduct: {
              name: self.ficaProductModel.name,
              description: self.ficaProductModel.description,
              category: self.ficaProductModel.category,
              createdBy: self.me.id,
            },
          },
        });

        if (!response) {
          throw "Something went wrong when creating the FicaProduct!";
        }
        if (response.errors && response.errors.length > 0) {
          throw response.errors[0];
        }
        if (response && response.data.createFicaProduct) {
          self.ficaProductModel.id = response?.data?.createFicaProduct?.id;
          window.location.href = `/admin/ficaproducts`;
        }
      } catch (e) {
        throw e.message;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
